<template>
<div>
    <v-dialog v-model="isOpen" persistent max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="close" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <v-form 
                    ref="form"
                    class="py-8 px-6" 
                    style="background-color: #FFFFFF;"
                    @submit.prevent 
                >
                    <slot name="form-header"></slot>
                    <v-menu transition="scale-transition" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-text-field 
                                    label="Desde" 
                                    class="mb-6 mb-sm-0 pr-sm-3"
                                    append-icon="mdi-calendar"
                                    placeholder="Fecha de inicio"
                                    light 
                                    outlined 
                                    readonly
                                    :error-messages="fecha_inicioErrors"
                                    :value="fechaInicio"
                                    @change="$v.form.fecha_inicio.$touch()"
                                    @blur="$v.form.fecha_inicio.$touch()"
                                />
                            </div>
                        </template>
                        <div @click.stop>
                            <vc-date-picker 
                                v-model="form.fecha_inicio"
                                mode="date"
                                locale="es"    
                            />
                        </div>
                    </v-menu>
                    <v-menu transition="scale-transition" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-text-field 
                                    label="Hasta" 
                                    placeholder="Fecha de fin"
                                    light 
                                    class="mb-6 mb-sm-0 pr-sm-3"
                                    append-icon="mdi-calendar"
                                    outlined 
                                    readonly
                                    :value="fechaFin"
                                    :error-messages="fecha_finErrors"
                                    @change="$v.form.fecha_fin.$touch()"
                                    @blur="$v.form.fecha_fin.$touch()"
                                />
                            </div>
                        </template>
                        <div @click.stop>
                            <vc-date-picker 
                                v-model="form.fecha_fin"
                                mode="date"
                                locale="es"    
                            />
                        </div>
                    </v-menu>
                    <div class="mt-4 d-flex" style="gap: 16px;">
                        <v-btn class="flex-shrink-1 flex-grow-1" color="secondary" @click.stop="obtenerReporteEjecucion">Reporte en ejecución</v-btn>
                        <v-btn class="flex-shrink-1 flex-grow-1" color="primary" @click.stop="obtenerReporteContratacion">Reporte en contratación </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown 
            v-if="contador"
            color="info--text"
            :date-now="contador"
            :nextDay="finalizacionTimer"
            @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate';
import { Validator } from '@/utils/form-validation.js';

const initialFormState = {
    fecha_inicio: null,
    fecha_fin: null,
};

export default {
    name: "ModalFecha",
    mixins: [validationMixin],
    validations: {
        form: {
            fecha_inicio: { required },
            fecha_fin: { required },
        },
    },
    props: { 
        isOpen: { type: Boolean, },
    },
    data: () => ({
        form: {...initialFormState},
        contador: null,
        contador: null,
        finalizacionTimer: null,
        modalReporte : false,
        minDate: new Date(),
    }),
    emits: ['on-save','close'],
    computed:{
        fecha_inicioErrors() {
            return new Validator(this.$v.form.fecha_inicio).detect().getResult();
        },
        fecha_finErrors() {
            return new Validator(this.$v.form.fecha_fin).detect().getResult();
        },
        fechaInicio() {
            if (!this.form.fecha_inicio) return;

            return moment(this.form.fecha_inicio).format("DD-MM-YYYY")

        },
        fechaFin() {
            if (!this.form.fecha_fin) return;
            return moment(this.form.fecha_fin).format("DD-MM-YYYY")
        },
    },
    methods:{
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = initialFormState;
        },
        async obtenerReporteEjecucion() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.showLoader();
            const {status} = await this.services.Paac.generarReporteProcesoEjecucion({
                ...this.form,
            });
            if (status == 200) {
                this.modalReporte = true;
            }
            this.hideLoader();
            this.$emit('close');
            this.clearForm();
        },
        close() {
            this.$emit('close');
        },
        async obtenerReporteContratacion() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.showLoader();
            const {status} = await this.services.Paac.generarReporteProcesoContratacion({
                ...this.form,
            });
            if (status == 200) {
                this.modalReporte = true;
            }
            this.hideLoader();
            this.$emit('close');
            this.clearForm();
        },
    },
    watch:{
    modalReporte(value){
      if (value) {
        this.contador = moment();
        this.finalizacionTimer = moment().add(5, 'seconds');
      }	else {	
        this.contador = null;
        this.finalizacionTimer = null;
      }
    },
  },
};
</script>