<template>
  <div style="min-width: 200px; max-width: 320px; background: #FFFFFF;">
    <div v-if="cargando" class="d-flex justify-center align-center px-4 py-4" style="gap: 16px;">
      <v-progress-circular indeterminate :size="20" :width="2"></v-progress-circular>
      <p class="mb-0 text-caption text-uppercase font-weight-bold">cargando... </p>
    </div>

    <!-- LISTA DE DEPARTAMENTOS -->
    <v-expansion-panels v-else>
      <v-expansion-panel v-for="(seguimiento) in seguimientos" :key="seguimiento.departamento.id"
        :disabled="seguimiento.departamento.codigo === 'OBSERVATORIO'">
        <v-expansion-panel-header class="px-3">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center px-2" style="gap: 16px;">
              <v-icon> {{ seguimiento.departamento.icono }} </v-icon>
              <span class="primary--text">{{ seguimiento.departamento.nombre }}</span>
            </div>
            <div>
              <v-chip v-if="seguimiento.seguimiento?.estado" label class="flex-shrink-0"
                :color="`${seguimiento.seguimiento.estado.color_fondo} !important`"
                :text-color="`${seguimiento.seguimiento.estado.color_texto} !important`">
                {{ seguimiento.seguimiento.estado.nombre ?? 'Sin revisar' }}
              </v-chip>
              <v-chip v-else label class="flex-shrink-0">Sin revisar</v-chip>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="seguimiento.departamento.codigo !== 'OBSERVATORIO'" class="px-0">

          <v-divider />

          <v-list class="py-4">
            <v-list-item v-for="(asignacion, index) in mapearAsignaciones(seguimiento)" class="px-4" :key="index">
              <v-list-item-avatar>
                <v-icon :disabled="!asignacion.persona">mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-title v-if="asignacion.persona" class="text-body-2 font-weight-medium"
                  style="white-space: normal; word-break: break-word;">
                  {{ formatearNombrePersona(asignacion.persona) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="asignacion.persona" class="text-caption text-lowercase">{{
                  asignacion.subtitulo }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else class="text-caption text-uppercase">{{ asignacion.fallback
                  }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { obtenerNombreCompletoPersona } from '@/utils/data';

export default {
  name: 'SeguimientosDepartamentales',
  props: {
    seguimientos: { type: Array },
    cargando: { type: Boolean },
  },
  methods: {
    formatearNombrePersona(persona) {
      if (!persona) return '';

      return obtenerNombreCompletoPersona(persona);
    },
    mapearAsignaciones(seguimiento) {
      return [
        { persona: seguimiento.seguimiento?.asignado?.empleado?.persona, subtitulo: 'Asignado', fallback: ' Sin asignado' },
        { persona: seguimiento.seguimiento?.responsable?.empleado?.persona, subtitulo: 'Responsable', fallback: 'Sin responsable' }
      ];
    },
  },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
  padding: 0 !important;
}
</style>