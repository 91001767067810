<template>
  <!-- Vista general de procesos -->
  <section fluid>
    <p class="text-h4 secondary--text mb-0">Procesos de compra</p>
    <p class="text-h6 secondary--text m-0">Ejercicio {{ anioFiscal }}</p>

    <v-row align="center">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
            v-model="filters.nombre"
            label="Código / Nombre de proceso"
            outlined
            clearable
            hide-details
        />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
            label="Modalidad de compra"
            :items="ctlModalidades"
            item-value="id"
            item-text="nombre"
            outlined
            clearable
            hide-details
            v-model="filters.id_forma_contratacion"
        />
      </v-col>

      <v-col
          v-if="haveRoles(['ROLE_DIRECCION_COMPRAS']) && false"
          cols="12"
          sm="12"
          md="4"
      >
        <v-autocomplete
            label="Interés del proceso"
            :items="ctlIntereses"
            item-value="id"
            item-text="nombre"
            outlined
            clearable
            hide-details
            v-model="filters.id_interes_presidente"
        />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
            label="Estado"
            :items="ctlEstados"
            item-value="id"
            item-text="nombre"
            outlined
            hide-details
            clearable
            v-model="filters.id_seguimiento_proceso"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-menu
            ref="menuDataRange"
            v-model="menuDataRange"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="dateRangeText"
                    label="Desde - Hasta"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                    title="es"
                    :max="fechaMaximaFiltro"
                    v-model="fechas"
                    range
                    @change="setFiltrosFechas"
                    locale="es-Es"
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="filters.id_anio_fiscal"
              :items="listadoAnios"
              item-text="anio"
              item-value="anio"
              label="año fiscal"
              outlined
              hide-details
            /> 
          </v-col>
        </v-row>
      </v-col>
      <v-col
          cols="12"
          sm="12"
          md="4"
          v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS','ROLE_FILTRO_INSTITUCION_PROCESOS', 'ROLE_TACOP', 'ROLE_AUDITORIA_GLOBAL'])"
      >
        <v-autocomplete
            label="Institución"
            :items="instituciones"
            item-value="id"
            item-text="nombre_completo"
            outlined
            hide-details
            clearable
            v-model="filters.id_institucion"
        />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
                :items="columnasList"
                hide-details
                item-value="value"
                item-text="label"
                label="Columna"
                outlined
                v-model="col_filter"
                clearable
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                :items="orderByList"
                hide-details
                label="Ordenar por"
                outlined
                clearable
                :disabled="!col_filter"
                v-model="order_filter"
            />
          </v-col>
        </v-row>
      </v-col>

      <template v-if="esJefeDeSeguimiento && !esSuperAdmin">
        <v-col cols="12" sm="12" md="4">
          <v-select
            v-model="filters.id_estado_seguimiento"
            :items="estados.data"
            :loading="estados.isLoading"
            label="Estado de seguimiento"
            placeholder="Seleccione un estado"
            item-value="id"
            item-text="nombre"
            hide-details
            outlined
            clearable
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <ProgresiveLoadAutocomplete
            v-model="filters.id_responsable_seguimiento"
            :pageable="tecnicosSeguimiento"
            :loading="tecnicosSeguimiento.isLoading"
            label="Responsable de seguimiento"
            placeholder="Seleccione un analista"
            item-value="id"
            item-text="nombre"
            hide-details
            @search="buscarTenicosSeguimiento"
            @load-more="cargarMasTecnicosSeguimiento"
            clearable
          />
        </v-col>
      </template>


      <v-col v-if="ShowBotonReporte" cols="12" sm="12" md="4">
        <v-btn
          dark
          block
          x-large
          color="secondary"
          @click="(page = 1), obtenerReporteSegunPIP()"
        >
          <v-icon left>mdi mdi-calendar-range</v-icon> Reporte según PIP
        </v-btn>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <div class="d-flex align-center justify-space-around flex-column flex-sm-row" style="gap: 16px;">
          <v-btn
            dark
            color="secondary"
            class="flex-shrink-1 flex-grow-1"
            style="width: 100%;"
            @click="(page = 1), obtainListProcesos()"
            x-large
          >
            Buscar
          </v-btn>
          <v-btn
            outlined
            color="secondary"
            class="flex-shrink-1 flex-grow-1"
            style="width: 100%;"
            @click="clearFiltros()"
            x-large
          >
            Limpiar
          </v-btn>
          <v-btn
            v-if="ShowBotonReporte"
            dark
            color="secondary"
            class="flex-shrink-1 flex-grow-1"
            style="width: 100%;"
            @click="(page = 1), obtainReporte()"
            x-large
          >
            Reporte
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown
              v-if="contador"
              color="info--text"
              :date-now="contador"
              :nextDay="finalizacionTimer"
              @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
          >Cerrar
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p class="font-weight-bold secondary--text mt-8">Listado de procesos</p>
    <div
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS'])"
        class="d-flex align-center mb-2"
    >
      <div class="d-flex align-center mr-6">
        <v-icon size="35" class="customProcessColor">mdi-circle</v-icon>
        <p class="mb-0 ml-2">Procesos reservados</p>
      </div>
    </div>
    <data-table-component
        v-models:select="perPage"
        v-models:pagina="page"
        :headers="headers"
        :items="items"
        @paginar="paginate"
        :total_registros="totalRows"
        :show_loading="cargandoListaDeProcesos"
    >
      <template #[`item.id_tipo_proceso`]="{ item }">
        <v-icon
            style="font-size: 25px !important"
            v-if="item.id_tipo_proceso == 2"
            class="mr-2 customProcessColor"
        >mdi-circle
        </v-icon
        >
      </template>
      <template #[`item.codigo_proceso`]="{ item }">
        <span>
          {{ item.codigo_proceso }}
        </span>
      </template>
      <template #[`item.fecha_inicio_proceso`]="{ item }">
        <span>
          {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
        </span>
      </template>
      <template #[`item.institucion`]="{ item }">
        <span>
          {{ item.Institucion?.codigo }} -
          {{ item.Institucion?.nombre }}
        </span>
      </template>
      <template #[`item.fecha_contratacion`]="{ item }">
        <span>
          {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.monto_total`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.monto_total ?? item.suma_mercancia)
        }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <template v-if="item?.proceso_padre">
          <v-chip
              label
              dark
              :color="item?.proceso_padre?.SeguimientoProceso?.color"
              class="mt-1"
              v-if="item?.proceso_padre?.EstadoProceso.id == 1"
          >
            {{ item?.proceso_padre?.SeguimientoProceso?.nombre || "" }}
          </v-chip>
          <v-chip
              v-if="item?.proceso_padre?.EstadoProceso.id !== 1"
              :color="procesoEstado(item?.proceso_padre?.EstadoProceso.id)"
              label
              class="mt-1 white--text"
          >
            {{ item?.proceso_padre?.EstadoProceso.nombre }}
          </v-chip>
          <p v-if="item?.proceso_padre?.EstadoProceso.id === 4 && item?.proceso_padre?.id_tipo_proceso === 2">
            Este proceso está en construcción, en procesos con reserva
          </p>
        </template>
        <template v-else>
          <v-chip
              label
              dark
              :color="item.seguimiento_proceso?.color"
              class="mt-1"
              v-if="item.EstadoProceso.id == 1 "
          >
            {{ item.seguimiento_proceso?.nombre || "" }}
          </v-chip>
          <v-chip
              v-if="item.EstadoProceso.id !== 1"
              :color="procesoEstado(item.EstadoProceso.id)"
              label
              class="mt-1 white--text"
          >
            {{ item.EstadoProceso.nombre }}
          </v-chip>
          <p v-if="item.EstadoProceso.id === 4 && item.id_tipo_proceso === 2">
            Este proceso está en construcción, en procesos con reserva
          </p>
        </template>
      </template>
      <template v-slot:[`item.forma_contratacion.nombre`]="{ item }">
        {{ item.forma_contratacion.nombre }} ({{
          item.version == 1 ? "v1" : "v2"
        }})
      </template>
      <template #[`item.estado_seguimiento`]="{ item }">
        <div class="d-flex justify-center align-center" style="gap: 8px;">
          <v-chip
            v-if="esUsuarioObservatorio"
            label
            :color="`${item.seg_p_obs?.es_spo.color_fondo} !important`"
            :text-color="`${item.seg_p_obs?.es_spo.color_texto} !important`"
          >
            {{ item.seg_p_obs?.es_spo.nombre ?? 'Sin revisar' }}
          </v-chip>
          <v-chip
            v-else
            label
            :color="`${item.seg_proc?.es_sp.color_fondo} !important`"
            :text-color="`${item.seg_proc?.es_sp.color_texto} !important`"
          >
            {{ item.seg_proc?.es_sp.nombre ?? 'Sin revisar' }}
          </v-chip>

          <v-menu left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                v-on="on" 
                v-bind="attrs" 
                icon 
                color="secondary" 
                @click.stop="cargarEstadosSeguimientosDepartamentales(item)"
                :loading="estadosSeguimientosDepartamentales.isLoading && item.id === procesoSeleccionado?.id"
                :disabled="estadosSeguimientosDepartamentales.isLoading && item.id !== procesoSeleccionado?.id"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span @click.stop>
              <SeguimientosDepartamentales 
                :seguimientos="estadosSeguimientosDepartamentales.data"
                :cargando="estadosSeguimientosDepartamentales.isLoading"
              />
            </span>
          </v-menu>
        </div>
      </template>
      <template #[`item.responsable_seguimiento`]="{ item }">
        {{ item.seg_proc?.us_res ? formatearPersona(item.seg_proc.us_res) : 'Sin asignar' }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip
            v-if="
            (
                item.EstadoProceso.id ===  5 &&
                haveRoles(['ROLE_UACI', 'ROLE_OFICIAL_CUMPLIMIENTO'])
            ) ||
            (
                item.EstadoProceso.id === 5 &&
                haveRole('ROLE_UACI_TECNICO') &&
                item?.id_usuario_tecnico === userInfo?.user?.id
            ) ||
            (
                item.seguimiento_proceso?.id !== 1 &&
              item.EstadoProceso.id !== 5
            ) ||
            (
                item.seguimiento_proceso?.id === 1 &&
              item.EstadoProceso.id === 8
            ) ||
            haveRoles([
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) ||
            haveRole('ROLE_TITULAR_ESTABLECIMIENTO') &
              !haveRole('ROLE_DIRECCION_COMPRAS')
          "
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                @click="goToProcessDetail(item)"
                v-bind="attrs"
                v-on="on"
                color="secondary"
            >
              mdi-eye
            </v-icon>
          </template>
          <span> Ver seguimiento</span>
        </v-tooltip>

        <v-tooltip
            v-if="
            (haveRoles([
              'ROLE_UACI',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
              'ROLE_FRONT_LECTURA_CALLCENTER',
            ]) || item.Empleado?.id_usuario == userInfo?.user?.id) && item.seguimiento_proceso?.id !== 5 && ![4, 5, 7, 8, 9].includes(item.EstadoProceso.id) || (haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS']) && [5, 80, 81].includes(item.seguimiento_proceso?.id) )
          "
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                @click="goToProcessConfig(item)"
                v-bind="attrs"
                v-on="on"
                color="secondary"
            >
              mdi-cog
            </v-icon>
          </template>
          <span> Configuración</span>
        </v-tooltip>

        <!-- Boton para acceder a configuracion DINAC-->
        <v-tooltip
            v-if="
            haveRoles(['ROLE_DIRECCION_COMPRAS']) &&
            !haveRole('ROLE_TECNICO_DIRECCION_COMPRAS') &&
            ![4, 5, 8].includes(item.EstadoProceso.id)
          "
            top
        >
          <!--TODO:Redirigir a vista que esta haciendo Amimir-->
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                @click="
                $router.push({
                  name: 'pac-procesos-detalle',
                  params: { idProceso: item.id },
                })
              "
                v-bind="attrs"
                v-on="on"
                color="secondary"
            >
              mdi-tools
            </v-icon>
          </template>
          <span> Configuración</span>
        </v-tooltip>
        <!-- Boton para acceder a configuracion DINAC-->

        <v-tooltip v-if="validDeleteFtn(item)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                @click="(deleteDialog = true), (proceso_seleccionado = item.id)"
                v-bind="attrs"
                v-on="on"
                color="secondary"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> Eliminar</span>
        </v-tooltip>

        <v-tooltip
            v-if="
            haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO']) &&
            item.seguimiento_proceso.id == 9
          "
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click="asignarCdp(item.id)"
            >
              mdi-certificate
            </v-icon>
          </template>
          <span> Asignar CDP</span>
        </v-tooltip>

        <!-- Asignar seguimiento -->
        <v-tooltip v-if="esJefeDeSeguimiento && !esSuperAdmin" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click.stop="asignarProceso(item)"
            >
              mdi-clipboard-text-search
            </v-icon>
          </template>
          <span> Asignar responsable</span>
        </v-tooltip>

        <!-- ocultar/habilitar seguimiento -->
        <v-tooltip v-if="esJefeDeSeguimiento && !esSuperAdmin && item.seg_proc" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click.stop="confirmarCambioVisibilidadAsignacion(item)"
            >
              {{ item.seg_proc.seguimiento_oculto ? 'mdi-eye-lock-open' : 'mdi-eye-lock' }}
            </v-icon>
          </template>
          <span>{{ item.seg_proc.seguimiento_oculto ? 'Habilitar' : 'Ocultar' }} seguimiento</span>
        </v-tooltip>

        <v-tooltip v-if="esUsuarioObservatorio" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click.stop="abrirModalAsignacionObservatorio(item)">
              <v-icon color="secondary">mdi-home-search</v-icon>
            </v-btn>
          </template>
          <span>Actualizar seguimiento (observatorio)</span>
        </v-tooltip>
      </template>
    </data-table-component>

    <AgregarProcesoComponent
        :show="modalAddProceso"
        :close="closeModalAddProceso"
        :action="obtainListProcesos"
        :modalidades="ctlModalidades"
    />

    <dialogoConfirmacion
        :show="deleteDialog"
        title="¿Desea eliminar este proceso de compra?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        btnConfirmar="Aceptar"
        @close="deleteDialog = false"
        @confirm="deleteProceso()"
    />

    <ModalAsignarSeguimiento
      v-if="esJefeDeSeguimiento && !esSuperAdmin"
      :is-open="modalAsignacionSeguimientoAbierta"
      :id-proceso="procesoSeleccionado?.id"
      :asignacion-actual="asignacionActual"
      @on-visibility-change="cerrarModalAsignacionSeguimiento"
      @on-cambio-asignacion="obtainListProcesos"
    />

    <ModalFecha
      :isOpen="modalFiltro"
      @close="modalFiltro = false"
    />

    <ConfirmationModalComponent 
      :is-open="confirmacionCambioVisibilidadAsignacion"
      :is-loading="cambioVisibilidadAsignacion.isLoading"
      :description="`¿Desea ${procesoSeleccionado?.seg_proc?.seguimiento_oculto ? 'habilitar' : 'ocultar'} esta asignación?`"
      @cancel="cerrarConfirmacionCambioVisibilidadAsignacion"
      @confirm="cambiarVisibilidadAsignacion"
    />

    <ModalActualizarSeguimientoObservatorio 
      v-if="esUsuarioObservatorio"
      :is-open.sync="modalAsignacionObservatorioAbierta" 
      :proceso-compra="procesoSeleccionado"
      @on-save="obtainListProcesos"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";
import AgregarProcesoComponent from "./components/AgregarProcesoComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import { ProgresiveLoadAutocomplete } from '@/components/utils';
import ModalAsignarSeguimiento from '@/views/seguimientoProcesos/components/ModalAsignarSeguimiento.vue';
import ModalActualizarSeguimientoObservatorio from "./components/ModalActualizarSeguimientoObservatorio.vue";
import SeguimientosDepartamentales from './components/SeguimientosDepartamentales.vue';
import { Listener, Observable } from '@/utils/observable';
import {
  createPageable,
  togglePageable,
  setPageableResponse,
  shouldFetch,
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful,
} from '@/utils/loadable';
import ModalFecha from "../ProcesoCompraDoc/components/ModalFecha.vue";
import moment from "moment";
import { joinList } from "@/utils/text";

export default {
  name: "PACListView",
  components: {
    DataTableComponent,
    AgregarProcesoComponent,
    dialogoConfirmacion,
    ModalAsignarSeguimiento,
    ProgresiveLoadAutocomplete,
    ModalFecha,
    ConfirmationModalComponent,
    SeguimientosDepartamentales,
    ModalActualizarSeguimientoObservatorio,
  },
  data: () => ({
    items: [],
    listadoAnios: [],
    contador: null,
    finalizacionTimer: null,
    filters: {
      id_seguimiento_proceso: null,
      id_anio_fiscal: null,
      id_forma_contratacion: null,
      nombre: null,
      fecha_desde: null,
      fecha_hasta: null,
      id_interes_presidente: null,
      id_institucion: null,
      id_estado_seguimiento: null,
      id_responsable_seguimiento: null,
      ordenar_por: [],
    },
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlModalidades: [],
    modalReporte: false,
    modalFiltro: false,
    fechaFiltro: null,
    ctlEstados: [
      {
        id: 1,
        nombre: "Configuración proceso",
      },
      {
        id: 11,
        nombre: "Recepción de solicitudes",
      },
      {
        id: 3,
        nombre: "En ejecución",
      },
      {
        id: 5,
        nombre: "Contratación",
      },
      {
        id: 44,
        nombre: "Desierto",
      },
      {
        id: 77,
        nombre: "Sin Efecto",
      },
      {
        id: 79,
        nombre: "Suspendido",
      },
      {
        id: 80,
        nombre: "Seguimiento contractual",
      },
      {
        id: 81,
        nombre: "Liquidado",
      },
    ],
    ctlIntereses: [
      {id: 3, nombre: "Todos"},
      {id: 1, nombre: "Procesos de indole institucional"},
      {id: 2, nombre: "Procesos reservados"},
    ],
    ctlInstituciones: [],
    timer: null,
    modalAddProceso: false,
    deleteDialog: false,
    proceso_seleccionado: null,
    menuDataRange: false,
    fechas: ["", ""],
    columnasList: [
      {
        value: "mes_programado",
        label: "Mes programado",
      },
      {
        value: "fecha_solicitado",
        label: "Fecha solicitado",
      },
      {
        value: "monto_programado",
        label: "Monto programado",
      },
    ],
    orderByList: ["ASC", "DESC"],
    col_filter: null,
    order_filter: null,
    cargandoListaDeProcesos: false,
    // Seguimiento
    tecnicosSeguimiento: createPageable([], 25),
    filtrosTecnicosSeguimiento: {
      pagina: 1,
      registrosPorPagina: 25,
      nombre: '',
    },
    // Seguimiento de procesos
    modalAsignacionSeguimientoAbierta: false,
    procesoSeleccionado: null,
    logoutUnsubscriber: null,
    confirmacionCambioVisibilidadAsignacion: false,
    cambioVisibilidadAsignacion: createLoadable(null),
    estadosSeguimientosDepartamentales: createLoadable([]),
    modalAsignacionObservatorioAbierta: false,
  }),

  computed: {
    ...mapState(["anioFiscal", "userInfo"]),
    ...mapState(["userInfo"]),
    ...mapState("seguimientoProcesos", ["departamento", "estados"]),
    instituciones() {
      return this.ctlInstituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }))
    },
    fechaMaximaFiltro() {
      // Fecha actual en formato ISO 8601 format
      return moment().format("YYYY-MM-DD");
    },
    dateRangeText() {
      return this.fechas[0] == "" && this.fechas[1] == ""
          ? " "
          : moment(this.fechas[0]).format("D/M/Y") +
          " - " +
          moment(this.fechas[1]).format("D/M/Y");
    },
    ShowBotonReporte() {
      return (this.haveRole("ROLE_PROCESO_COMPRA_REPORTE_VIEW"))
    },
    esJefeDeSeguimiento() {
      return this.haveRoles([
        "ROLE_JEFE_SYC_SEGUIMIENTO_PROCESOS_VIEW",
        "ROLE_JEFE_FISCALIZACION_SEGUIMIENTO_PROCESOS_VIEW"
      ]);
    },
    esUsuarioObservatorio() {
      return this.haveRoles(['ROLE_OBSERVATORIO']);
    },
    esSuperAdmin() {
      return this.haveRoles(['ROLE_SUPER_ADMIN']);
    },
    asignacionActual() {
      if (!this.procesoSeleccionado) return {};

      return {
        departamento: this.procesoSeleccionado.seg_proc?.id_departamento,
        responsable: this.procesoSeleccionado.seg_proc?.us_res?.id,
        asignado: this.procesoSeleccionado.seg_proc?.us_as?.id,
        estado: this.procesoSeleccionado?.seg_proc?.es_sp.id,
      }
    },
    filtroEstadoSeguimiento() {
      return this.filters.id_estado_seguimiento;
    },
    headers() {
      const tableHeaders = [
        {
          text: "",
          value: "id_tipo_proceso",
          sortable: false,
          align: "center",
        },
        {
          text: "Código",
          value: "codigo_proceso",
          sortable: false,
        },
        {
          text: "Código interno",
          value: "codigo_interno",
          sortable: false,
        },
        {
          text: "Proceso",
          value: "nombre_proceso",
          sortable: false,
        },
        {
          text: "Modalidad de compra",
          value: "forma_contratacion.nombre",
          align: "left",
          sortable: false,
        },
        {
          text: "Institución",
          value: "institucion",
          align: "left",
          sortable: false,
        },
        {
          text: "Mes programado",
          value: "fecha_inicio_proceso",
          align: "right",
          sortable: false,
        },
        {
          text: "Fecha solicitado",
          value: "fecha_contratacion",
          align: "right",
          sortable: false,
        },
        {
          text: "Monto planificado($)",
          value: "monto_total",
          align: "right",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
        },
      ];

      if (this.esJefeDeSeguimiento || this.esUsuarioObservatorio) {
        tableHeaders.push({
          text: "Estado de seguimiento",
          value: "estado_seguimiento",
          align: "center",
          sortable: false,
        });
      }

      if (this.esJefeDeSeguimiento) {
        tableHeaders.push({
          text: "Responsable de seguimiento",
          value: "responsable_seguimiento",
          align: "center",
          sortable: false,
        });
      }

      tableHeaders.push({
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      });

      return tableHeaders;
    }
  },

  methods: {
    ...mapActions("seguimientoProcesos", ["cargarDepartamentoUsuarioLogueado", "cargarCatalogoDeEstados"]),
    ...mapMutations("seguimientoProcesos", ["resetDepartamentoUsuarioLogueado"]),
    validDeleteFtn(item_param) {
      if (this.haveRole("ROLE_UACI_TECNICO")) {
        return (
            item_param.seguimiento_proceso?.id == 1 &&
            item_param.id_usuario == this.userInfo.user?.id &&
            item_param.EstadoProceso.id == 1
        );
      } else if (
          this.haveRoles([
            "ROLE_UACI",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
          ])
      ) {
        return (
            item_param.seguimiento_proceso?.id == 1 &&
            item_param.EstadoProceso.id == 1
        );
      }
    },
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.obtainListProcesos();
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.obtainListProcesos();
      }, 500);
    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },
    async fetchInstituciones() {
      const response =
          await this.services.Instituciones.getCatalogoInstituciones({
            pagination: false,
          });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
    setFiltrosFechas() {
      this.filters.fecha_desde = this.fechas[0];
      this.filters.fecha_hasta = this.fechas[1];
    },
    procesoEstado(id) {
      switch (id) {
        case 5:
          return "error";
        case 6:
          return "amber lighten-3";
        case 7:
          return "purple lighten-2";
        case 8:
          return "info";
        default:
          break;
      }
    },
    nombreEstado(id) {
      switch (id) {
        case 5:
          return "Proceso desierto";
        case 6:
          return "Proceso suspendido";
        case 8:
          return "Proceso cancelado";
        default:
          break;
      }
    },
    clearFiltros() {
      this.filters.nombre = null;
      this.filters.id_forma_contratacion = null;
      this.filters.id_interes_presidente = null;
      this.filters.id_seguimiento_proceso = null;
      this.fechas = ["", ""];
      this.filters.fecha_desde = null;
      this.filters.fecha_hasta = null;
      this.filters.id_institucion = null;
      this.filters.ordenar_por = [];
      this.col_filter = null;
      this.order_filter = null;
      this.filters.id_estado_seguimiento = null;
      this.filters.id_responsable_seguimiento = null;
      this.filters.id_anio_fiscal = null;
      this.obtainListProcesos();
    },
    async obtainListProcesos() {
      try {
        this.cargandoListaDeProcesos = true;
        if (this.order_filter && this.col_filter) this.filters.ordenar_por = [this.col_filter, this.order_filter];

          const filtros = {
            ...this.filters,
            page: this.page,
            per_page: this.perPage,
          };

        const { status, data, headers } = await this.services.Paac.getListProcesos(filtros);

        if (status == 200) {
          this.items = data.procesos;
          this.totalRows = Number(headers.total_rows);
        }
      } catch (error) {}
      finally {
        this.cargandoListaDeProcesos = false;
      }
    },

    async obtainReporte() {
      this.showLoader();
      const {status} = await this.services.Paac.generarReporteProceso({
        ...this.filters,
        total_rows: this.totalRows,
      });
      if (status == 200) {
        this.modalReporte = true;
      }
      this.hideLoader();
    },
    async obtenerReporteSegunPIP() {
      this.modalFiltro = true;
    },
    async manejarFiltroFechas(data){
      this.fechaFiltro = data
      this.filters.fecha_inicio = data.fecha_inicio
      this.filters.fecha_fin = data.fecha_fin
    },
    closeModalAddProceso() {
      this.modalAddProceso = false;
    },
    async deleteProceso() {

      const {status} = await this.services.PacProcesos.deleteProcesoCompra(
          this.proceso_seleccionado
      ).catch(() => {

      });
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Proceso eliminado con éxito`,
          type: "success",
        });
        setTimeout(() => {

        }, 1500);
        this.obtainListProcesos();
      }
      this.deleteDialog = false;
    },
    asignarCdp(idProceso) {
      this.$router.push({name: "proceso-asignar-cdp", params: {idProceso}});
    },
    goToProcessDetail(proceso) {
      if (proceso.version === 2) {
        window.open(
            `${location.origin}/pac-procesos/etapas/${proceso.id}`,
            "_blank"
        );
      } else if (proceso.version === 1) {
        window.open(
            `${location.origin}/pac-procesos-v1/etapas/${proceso.id}`,
            "_blank"
        );
      }
    },
    goToProcessConfig(proceso) {
      if (proceso.version === 2) {
        window.open(
            `${location.origin}/pac-procesos/configuracion/${proceso.id}`,
            "_blank"
        );
      } else if (proceso.version === 1) {
        window.open(
            `${location.origin}/pac-procesos-v1/configuracion/${proceso.id}`,
            "_blank"
        );
      }
    },
    async cargarTecnicosSeguimiento() {
        const filtros = {
          pagination: true,
          page: this.filtrosTecnicosSeguimiento.pagina,
          per_page: this.filtrosTecnicosSeguimiento.registrosPorPagina,
          nombre: this.filtrosTecnicosSeguimiento.nombre,
        };
        togglePageable(this.tecnicosSeguimiento);
        const { data, headers } = await this.services.SeguimientoProcesos.obtenerTecnicosDepartamento(filtros);
        setPageableResponse(this.tecnicosSeguimiento, data, headers);
    },
    async buscarTenicosSeguimiento(termino) {
        if (this.filtrosTecnicosSeguimiento.nombre  === termino) return;

        this.tecnicosSeguimiento.data = [];
        this.filtrosTecnicosSeguimiento.nombre = termino;
        this.filtrosTecnicosSeguimiento.pagina = 1;
        this.cargarTecnicosSeguimiento();
    },
    async cargarMasTecnicosSeguimiento() {
        if (this.tecnicosSeguimiento.isLoading) return;
        const { page, per_page, total_rows } = this.tecnicosSeguimiento.pagination;
        const currentlyLoaded = page * per_page;
        if (!(currentlyLoaded < total_rows)) return;

        this.filtrosTecnicosSeguimiento.pagina = page + 1;
        this.filtrosTecnicosSeguimiento.registrosPorPagina = per_page;
        this.cargarTecnicosSeguimiento();
    },
    asignarProceso(proceso) {
      this.procesoSeleccionado = { ...proceso };
      this.modalAsignacionSeguimientoAbierta = true;
    },
    cerrarModalAsignacionSeguimiento(visible) {
      this.modalAsignacionSeguimientoAbierta = visible;
    },
    formatearPersona(persona) {
        if (!persona) return '-';

        const { primer_nombre, segundo_nombre, tercer_nombre, primer_apellido, segundo_apellido, apellido_casada } = persona.Empleado.Persona;
        const componentes = [
            primer_nombre,
            segundo_nombre,
            tercer_nombre,
            primer_apellido,
            segundo_apellido,
            apellido_casada,
        ];

        return joinList(componentes, ' ');
    },
    // Cambio visibilidad asignacion
    confirmarCambioVisibilidadAsignacion(proceso) {
      this.procesoSeleccionado = {...proceso};
      this.confirmacionCambioVisibilidadAsignacion = true;
    },
    cerrarConfirmacionCambioVisibilidadAsignacion(visible) {
      this.confirmacionCambioVisibilidadAsignacion = visible;
    },
    async cambiarVisibilidadAsignacion(){
      const idAsignacion = this.procesoSeleccionado.seg_proc.id;
      const payload = { ocultar: !this.procesoSeleccionado.seg_proc.seguimiento_oculto };

      toggleLoadable(this.cambioVisibilidadAsignacion);
      const { data } = await this.services.SeguimientoProcesos.cambiarVisibilidad(idAsignacion, payload);
      this.confirmacionCambioVisibilidadAsignacion = false;
      setLoadableResponse(this.cambioVisibilidadAsignacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });

      if (isResponseSuccesful(data)) this.obtainListProcesos();
    },
    async obtenerAniosFiscales() {
      const {data} = await this.services.PacProcesos.cargarAniosFiscales();
      this.listadoAnios = data;
    },
    async cargarEstadosSeguimientosDepartamentales(proceso) {
      this.procesoSeleccionado = { ...proceso };
      this.estadosSeguimientosDepartamentales = createLoadable([]);
      toggleLoadable(this.estadosSeguimientosDepartamentales);
      const { data } = await this.services.SeguimientoProcesos.cargarEstadosSeguimiento(proceso.id);
      setLoadableResponse(this.estadosSeguimientosDepartamentales, data);
    },
    // Observatorio
    abrirModalAsignacionObservatorio(proceso) {
      this.procesoSeleccionado = {...proceso};
      this.modalAsignacionObservatorioAbierta = true;
    },
  },
  watch: {
    modalReporte(value) {
      if (value) {
        this.contador = moment();
        this.finalizacionTimer = moment().add(5, 'seconds');
      } else {
        this.contador = null;
        this.finalizacionTimer = null;
      }
    },
    // filtroEstadoSeguimiento(value) {
      // if (value === 1) this.filters.id_responsable_seguimiento = null;
    // },
  },
  created() {
    this.obtainListProcesos();
    this.fetchModalidades();
    this.fetchInstituciones();
    this.obtenerAniosFiscales();

    if (this.esJefeDeSeguimiento && !this.esSuperAdmin) {
      this.cargarTecnicosSeguimiento();
      this.cargarCatalogoDeEstados();

      if (shouldFetch(this.departamento)) this.cargarDepartamentoUsuarioLogueado();

      const listener = new Listener('pac-view').setHandler(this.resetDepartamentoUsuarioLogueado);
      this.logoutUnsubscriber = Observable.getInstance().subscribe('logout', listener);
    }
  },
  beforeDestroy() {
    this.logoutUnsubscriber?.();
  },
};
</script>
<style>
.customProcessColor {
  color: #73d2de !important;
}
</style>
