<template>
  <v-dialog persistent v-model="show" max-width="800">
    <v-card>
      <v-card-title>
        <p class="text-h5 secondary--text">Creación de proceso de compra</p>
      </v-card-title>
      <v-card-subtitle>
        <span class="text-subtitle-1 secondary--text"
          >Ejercicio {{ anioFiscal }}</span
        >
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Código interno del proceso"
              v-model="proceso.codigo_interno"
              :error-messages="codigoProcesoError"
              @input="$v.proceso.codigo_interno.$touch()"
              counter
              maxlength="250"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nombre del proceso"
              v-model="proceso.nombre"
              :error-messages="nombreProcesoError"
              @input="$v.proceso.nombre.$touch()"
              counter
              maxlength="500"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <money-text-field-component
              ref="mercancia"
              label="Suma de mercancía *"
              v-model="proceso.suma_mercancia"
              outlined
              :error-messages="montoErrors"
              @input="$v.proceso.suma_mercancia.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="proceso.id_forma_contratacion"
              :items="modalidades"
              item-text="nombre"
              item-value="id"
              label="Modalidad de compra *"
              outlined
              @blur="$v.proceso.id_forma_contratacion.$touch()"
              @input="$v.proceso.id_forma_contratacion.$touch()"
              :error-messages="modalidadError"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="text-center">
            <p class="mb-0 text-start">Inicio del proceso</p>
            <v-date-picker
              v-model="picker"
              type="month"
              locale="es-Es"
              header-color="#404a7a"
              style="margin-right: 40px; margin-top: 15px"
              :min="moment().format('YYYY-MM-DD')"
            />
          </v-col>
          <v-col cols="12" sm="6" class="text-center">
            <p class="mb-0 text-start">Finalización del proceso</p>
            <v-date-picker
              :min="picker"
              :disabled="picker == ''"
              v-model="picker2"
              type="month"
              locale="es-Es"
              style="margin-top: 15px; color-text: white"
              header-color="#404a7a"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="py-5">
        <v-row class="mt-8 display-flex justify-center">
          <v-col cols="12" sm="4">
            <v-btn
              block
              outlined
              color="secondary"
              @click="close(), clearData()"
              >Volver</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn block color="secondary" @click="guardarDatosProceso"
              >Guardar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);
const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

export default {
  name: "AgregarProcesoComponent",
  components: {
    MoneyTextFieldComponent,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
    },
    action: {
      type: Function,
    },
    modalidades: {
      type: Array,
    },
  },
  data: () => ({
    proceso: {
      codigo_interno: null,
      nombre: "",
      id_forma_contratacion: null,
      suma_mercancia: 0,
    },
    picker: moment().format("YYYY-MM"),
    picker2: null,
  }),
  validations: {
    proceso: {
      codigo_interno: { letter },
      nombre: { required, letter },
      id_forma_contratacion: { required },
      suma_mercancia: { required, priceDecimals },
    },
  },
  computed: {
    ...mapState(["anioFiscal"]),
    codigoProcesoError() {
      const errors = [];
      if (!this.$v.proceso.codigo_interno.$dirty) return errors;
      !this.$v.proceso.codigo_interno.letter &&
        errors.push(
          "El código del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.proceso.nombre.$dirty) return errors;
      !this.$v.proceso.nombre.required &&
        errors.push("El nombre del proceso es requerido");
      !this.$v.proceso.nombre.letter &&
        errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.proceso.suma_mercancia.$dirty) return errors;
      !this.$v.proceso.suma_mercancia.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.proceso.suma_mercancia.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.proceso.id_forma_contratacion.$dirty) return errors;
      !this.$v.proceso.id_forma_contratacion.required &&
        errors.push("La modalidad de compra es requerida");
      return errors;
    },
  },
  methods: {
    async guardarDatosProceso() {

      this.$v.$touch();

      if (this.$v.$invalid) return;
      if (this.picker == null || this.picker2 == null) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe establecer el inicio y fin del proceso",
        });
        return;
      }
      const data = {
        ...this.proceso,
        fecha_inicio: this.moment(this.picker)
          .startOf("month")
          .format("YYYY-MM-DD"),
        fecha_fin: this.moment(this.picker2)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const { status } = await this.services.PacProcesos.postProcesoCompra(
        data
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Proceso de compra creado con éxito",
        });
        this.close();
        this.clearData();
        this.action();
      }
    },

    clearData() {
      this.proceso.codigo_interno = null;
      this.proceso.nombre = null;
      this.proceso.suma_mercancia = 0;
      this.$refs.mercancia.updateValue(0);
      this.proceso.id_forma_contratacion = null;

      this.picker = moment().format("YYYY-MM");
      this.picker2 = "";
      this.$v.$reset();
    },
  },
  watch: {
    picker() {
      if (!this.$route.params.idProceso) this.picker2 = this.picker;
    },
  },
};
</script>
