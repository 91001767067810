<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card>
                <v-card-title class="justify-space-between align-center">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <v-progress-circular v-if="seguimientoActual.isLoading" indeterminate :size="20" :width="2" />
                    <v-chip
                        v-else
                        label
                        class="flex-shrink-0"
                        :color="`${seguimientoActual.data?.estado.color_fondo} !important`"
                        :text-color="`${seguimientoActual.data?.estado.color_texto} !important`"
                    >
                        {{ seguimientoActual.data?.estado.nombre ?? 'Sin revisar' }}
                    </v-chip>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <dl v-if="procesoCompra" class="mt-4">
                        <dt class="font-weight-bold">Código de proceso:</dt>
                        <dd>{{ procesoCompra.codigo_proceso }}</dd>
                        <dt class="font-weight-bold mt-2">Nombre de proceso:</dt>
                        <dd>{{ procesoCompra.nombre_proceso }}</dd>
                        <dt class="font-weight-bold mt-2">Institución:</dt>
                        <dd>{{ procesoCompra.Institucion.nombre }}</dd>
                    </dl>
                    <v-select 
                        v-model="estado"
                        :items="estados.data" 
                        :loading="estados.isLoading || seguimientoActual.isLoading" 
                        item-value="id" 
                        item-text="nombre" 
                        placeholder="Seleccione un estado"
                        label="Estado"
                        class="mt-4"
                        outlined
                    />
                    <div class="d-flex justify-center align-center" style="position: sticky; bottom: 0; background: white; gap: 16px;">
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary" 
                            large
                            @click.stop="cerrarModal"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary"
                            large
                            :disabled="!estado || seguimientoActual.isLoading"
                            @click.stop="confirmarActualizacionEstado"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmationModalComponent 
            :isOpen="confirmacionAbierta" 
            description="¿Desea actualizar el estado del seguimiento para el proceso de compra seleccionado?"
            @confirm="cambiarEstadoSeguimiento" 
            @cancel="confirmacionAbierta = false"
            :isLoading="cambioEstado.isLoading" 
        />
    </Fragment>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, shouldFetch, toggleLoadable } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { Fragment } from 'vue-fragment';

export default {
    name: 'ModalActualizarSeguimientoObservatorio',
    emits: ['on-save'],
    components: { ConfirmationModalComponent, Fragment },
    props: {
        isOpen: { type: Boolean },
        procesoCompra: { type: Object },
    },
    data: () => ({
        // UI
        estado: null,
        confirmacionAbierta: false,
        // DATA
        estados: createLoadable([]),
        seguimientoActual: createLoadable(null),
        cambioEstado: createLoadable(null),
    }),
    methods: {
        // UI
        emitirCierreModal() {
            this.$emit('update:is-open', false);
        },
        cerrarModal() {
            this.emitirCierreModal();
            this.estado = null;
            this.seguimientoActual = createLoadable(null);
        },
        confirmarActualizacionEstado() {
            this.emitirCierreModal();
            this.confirmacionAbierta = true;
        },
        // Data
        async cargarCatalogoEstados() {
            toggleLoadable(this.estados);
            const { data }  = await this.services.SeguimientoProcesos.cargarCatalogoEstadosObservatorio();
            setLoadableResponse(this.estados, data);
        },
        async cargarSeguimientoActual() {
            toggleLoadable(this.seguimientoActual);
            const { data } = await this.services.SeguimientoProcesos.buscarSeguimientoObservatorioPorProceso(this.procesoCompra.id);
            setLoadableResponse(this.seguimientoActual, data);

            if (isResponseSuccesful(data)) {
                this.estado = data.data?.estado.id;
            }
        },
        async cambiarEstadoSeguimiento() {
            toggleLoadable(this.cambioEstado);
            const { data } = await this.services.SeguimientoProcesos.actualizarEstadoSeguimientoObservatorio(this.procesoCompra.id, this.estado);
            this.confirmacionAbierta = false;
            setLoadableResponse(this.cambioEstado, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.$emit('on-save');
                this.seguimientoActual = createLoadable(null);
            }
        },
    },
    watch: {
        isOpen(value) {
            if (value && shouldFetch(this.estados)) {
                this.cargarCatalogoEstados();
            }

            if (value && this.procesoCompra?.id) {
                this.cargarSeguimientoActual();
            }
        },
    },
}
</script>